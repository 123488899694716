/* media-queries.less */
/*
Deffined on variables.less from bootstrap
Large screen 		/ wide desktop 		/ screen >= 1200px
Medium screen 		/ desktop 			/ 992px >= screen < 1200px
Small screen 		/ tablet 			/ 768px >= screen < 992px
Extra small screen 	/ phone 			/ 480px >= screen < 768px
Very small screen 	/ Outdated phone 	/ screen < 480px
*/
/* Large screen & Biger */
@media (min-width: @screen-lg) {
	//@import 'mq-lgnb.less';
	
}
/* Medium screen & Biger */
@media (min-width: @screen-md) {
	h1
	{
		font-size:26px;
	}
	.div_product_module h2
	{
		font-size:26px;
	}
	.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand 
	{
		margin-left:15px;
	}
	footer .div_footer_col
	{
		margin-top:0px!important;
	}
	/*start header*/
	ul.nav li.dropdown:hover > ul.dropdown-menu
	{
		display: block;    
	}
	.navbar-default li:hover,
	.navbar-default li a.active
	{
		-webkit-box-shadow:inset 0px -4px 0px 0px #fff;
		-moz-box-shadow:inset 0px -4px 0px 0px #fff;
		box-shadow:inset 0px -4px 0px 0px #fff;  
	}
	.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand 
	{
	
		margin-left:0px;
	}
	/*end header*/
	.container
	{
		width: 100%;
	}
}
/* Small screen & Biger */
@media (min-width: @screen-sm) {
	//@import 'mq-smnb.less';
	.div_product_paginatitel
	{
		/*margin-left: 15px!important;
		padding-left:0;*/
	}
}
/* Extra small screen & Biger */
@media (min-width: @screen-xs) {

}
/* Medium screen and smaller */
@media (max-width: @screen-md-max) {
	//@import 'mq-mdns.less';
}
/* Small screen and smaller */
@media (max-width: @screen-sm-max) {
	//@import 'mq-smns.less';
}
/* Extra small screen and smaller */
@media (max-width: @screen-xs-max)
{
	.div_products
	{
		margin-left: 0px!important;	
	}
	.div_zoeken_form_outer
	{
		margin-bottom:15px;
	}
	.div_product_paginatitel
	{
		/*margin-left: 0px;
		padding-left:15px;*/
	}
}
/* smaller than Extra small screen */
@media (max-width: (@screen-xs - 1)) {
	//@import 'mq-stxs.less';
}
/* Only Medium screen */
@media (min-width: @screen-md) and (max-width: @screen-md-max) {
	//@import 'mq-only-md.less';
}
/* Only Small screen */
@media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
	//@import 'mq-only-sm.less';
}
/* Only Extra small screen */
@media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
	//@import 'mq-only-xs.less';
}

@media (min-width: 1600px)
{
	.container
	{
		width: 1600px;
	}
}